const mentor_menu_data = [
  // {
  //   id: 1,
  //   title: "Dashboard",
  //   link: "/mentor-dashboard",
  // },
  {
    id: 2,
    title: "Profile ",
    link: "/mentor-profile",
  },
  {
    id: 3,
    title: "Add Course",
    link: "/manage-course",
  },
  {
    id: 7,
    title: "PDF Manager",
    link: "/mentor-pdf-manager",
  },
  {
    id: 4,
    title: "Students",
    link: "/student-collection",
  },
  {
    id: 5,
    title: "Coupons",
    link: "/mentor-coupons-listing",
  },
  {
    id: 6,
    title: "Coupons Used",
    link: "/mentor-coupons-usedby-students",
  },
];

export default mentor_menu_data;
