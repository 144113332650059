import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getMentorlisting } from "../../services/mentor-service";
import { Card, Form, Table, Row, Col, Badge, Container } from "react-bootstrap";
import { GetMentorStudentCollectionbyId } from "../../services/mentor-service";
import { getDataFromLocalStorage } from "../../browser-storage";
import { toast } from "react-toastify";
const StudentCollection = () => {
  const [mentorlist, setMetorList] = useState([]);
  const [studentcollection, setStudentCollection] = useState([]);
  let ArrayFormat = [];
  useEffect(() => {
    GetMentorProfileById();
  }, []);

  let GetMentorProfileById = async () => {
    const MentorDetials = getDataFromLocalStorage("user-details");
    console.log(MentorDetials);

    const getmentorusername = MentorDetials.userName;

    const SubmitResponse = await GetMentorStudentCollectionbyId(
      getmentorusername
    )
      .then((resp) => {
        console.log("resp", resp);
        for (let userdetials of resp) {
          let userMasterData = userdetials.user_score_master;
          let aptitudescore = userdetials.aptitudescore["rightAns"];
          let softskillscore = userdetials.softskillscore["score"];
          let userresumeUrl = "";
          if (userdetials.userresumeUrl) {
            userresumeUrl =
              "https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/" +
              userdetials.userresumeUrl;
          }

          ArrayFormat.push({
            uid: userdetials.uid,
            username: userdetials.username,
            userfullname: userdetials.userfullname,
            mobile: userdetials.mobile,
            collegename: userdetials.collegename,
            degreecode: userdetials.degreecode,
            softskillscore: softskillscore,
            aptitudescore: aptitudescore,
            mentorscore: userdetials.ScoreGivenByMentor,
            aiinterviewscore: userdetials.aiinterviewscore,
            mentorremark: userdetials.RemarkGivenByMentor,
            userresumeUrl: userresumeUrl,
          });
        }

        console.log("ArrayFormat", ArrayFormat);
        //setAppliedbyList(ArrayFormat);
        setStudentCollection(ArrayFormat);
      })
      .catch((err) => {
        console.log(err);

        toast.error(`Error ${err.response.data} `, {
          position: "top-left",
        });
      });
  };
  return (
    <Wrapper>
      <SEO pageTitle={"Mentors"} />
      <>
        <section
          className="page__title-area page__title-height page__title-overlay d-flex align-items-center"
          style={{
            background: `url(/assets/img/ghimages/try/2.jpg)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="page__title-wrapper mt-110">
                  <h3 className="page__title">Your Students</h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="teacher__area pt-50 pb-50">
          <div className="container">
            <div className="row">
              <div className="col-xxl-6 offset-xxl-3">
                <div className="section__title-wrapper text-center mb-60">
                  <h2 className="section__title">
                    {" "}
                    <span className="yellow-bg">
                      {" "}
                      Your Students
                      <img
                        src="/assets/img/shape/yellow-bg-2.png"
                        alt=""
                      />{" "}
                    </span>{" "}
                    <br />
                  </h2>
                  {/* <p>
                    You don't have to struggle alone, you've got our assistance
                    and help.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Student Email</th>

              <th>Student Name</th>
              <th>Applied Date</th>
              <th>Mobile No.</th>
              <th>College Name</th>
              <th>Degree</th>
              <th>Soft Skill score</th>
              <th>Aptitude score</th>
              <th>AI interview Score</th>
              <th>Mentor Score</th>
              <th>Resume Link</th>
            </tr>
          </thead>
          <tbody>
            {studentcollection.map((item) => {
              return (
                <tr>
                  <td>{item.username}</td>
                  <td>{item.userfullname}</td>
                  <td>{item.appliedbydate}</td>
                  <td>{item.mobile}</td>
                  <td>{item.collegename}</td>
                  <td>{item.degreecode}</td>
                  <td>{item.softskillscore}</td>
                  <td>{item.appliedbyaptitudescore}</td>
                  <td>{item.appliedbyaiscore}</td>
                  <td>{item.mentorscore}</td>
                  <td>
                    <Link
                      to="/mentor-resume-builder-tool"
                      state={{ data: item.username }}
                      className="e-btn"
                    >
                      Access Resume
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
    </Wrapper>
  );
};

export default StudentCollection;
