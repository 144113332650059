import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

import ProgressBar from "../assessment/common/progressBar";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import {
  AssessmentType,
  ScoreCardConstants,
} from "../../../redux/types/generic-constant";
import {
  getAssessmentResult,
  getModifiedAssessmentResult,
} from "../../../services/assessment";
import {
  AptitudeResponse,
  QuestionType,
  SoftSkillResponse,
} from "../../../redux/types/model/assessment/aptitude";
import { useReactToPrint } from "react-to-print";
import { getQueryParams } from "../../../helpers";
import { AppConfigEvent } from "../../../redux/types/redux-event";
import { toast } from "react-toastify";
import Wrapper from "../../../layout/wrapper";
import "../assessment/score-card.css";
import ScoreBar from "./score-bar";
//Complete later
//import Header from "components/dashboard-header";

// interface StyledPros {
//   scorePer: number
//   paddingTop?: string
//   fontWeight?: number
//   fontSize?: number
//   paddLeft?: number
//   mrgBtm?: number
//   color?: string
// }

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  //height: 70vh;
  padding: 35px;
  background: #ffffff;
  justify-content: center;
  //align-items: center;
`;
const HeadText = styled.div`
  display: flex;
  width: 100%;
  //max-width: 435px;
  padding-left: 25px;
  box-shadow: 0 3px 3px 3px #c4c4c4;
  color: #3e3d43;
  font-size: 28px;
  font-weight: 500;
  padding-bottom: 30px;
  padding-top: 30px;
`;
const UserPerformance = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  border-left: 6px solid #e0e0e0;
`;

const InformationBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 42px;
  justify-content: space-between;
`;
const UserInfoColumn = styled.div`
  display: flex;
  width: 100%;
  max-width: 300px;
`;
const RemarksColumn = styled.div`
  display: flex;
  width: 100%;
  max-width: 1100px;
  //flex-direction: column;
`;
const PercentageColumn = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  box-shadow: 1px 3px 3px 3px #c4c4c4;
  // max-height: 130px;
  padding: 20px;
`;
const UserDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
  align-items: center;
  padding-left: 20px;
  max-height: 150px;
  border-left: 6px solid #e0e0e0;
`;
const Fields = styled.span`
  display: flex;
  width: 100%;
  max-width: 350px;
  color: rgba(62, 61, 67, 0.74);
  font-size: 13px;
  align-items: center;
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : "0")};
`;

const FieldValue = styled.span`
  display: flex;
  font-size: 13px;
  align-items: center;
  padding-left: 5px;
  color: black;
`;
const ProgressBarContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  font-weight: 600;
  max-width: 950px;
  min-width: 550px;
  border-radius: 2px;
  background-color: #f5f5f5;
`;
const ProgressBarBlock = styled.div`
  display: flex;
  width: ${(props) => (props.scorePer ? `${props.scorePer}%` : "0%")};
  height: 30px;
  border-radius: 2px;
  background-color: ${(props) =>
    props.scorePer <= 30
      ? "#EA5252"
      : props.scorePer > 31 && props.scorePer <= 50
      ? "#90EE90"
      : props.scorePer > 51 && props.scorePer <= 70
      ? "#90EE90"
      : "#1C8D39"};
  flex-direction: column;
  align-items: center;
`;

const SpanText = styled.div`
  display: flex;
  width:200px
  flex-direction: row;
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "23px")};
  font-weight: ${(props) => (props.fontWeight ? `${props.fontWeight}` : "500")};
  margin-bottom: ${(props) => (props.mrgBtm ? `${props.mrgBtm}` : "unset")};
  padding-left: ${(props) =>
    props.paddLeft ? `${props.paddLeft}px` : "unset"};
  color: ${(props) => (props.paddLeft ? `${props.color}` : "#3E3D43")};
  align-items: center;
`;

const OverallProgress = styled.div`
  display: flex;
  width: 100%;
  background: ${(props) =>
    props.scorePer <= 30
      ? "#EA5252"
      : props.scorePer > 31 && props.scorePer <= 50
      ? "#F9A825"
      : props.scorePer > 51 && props.scorePer <= 70
      ? "#0DADAD"
      : "#1C8D39"};
  height: 34px;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 25px;
`;

const DetailsBlock = styled.div`
  width: 100%;
  //padding-left: 20px;
  display: flex;
  flex-direction: column;
`;
const OverallBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`;
const PerDiv = styled.div`
  width: 100%;
  background: #7e7e7e;
  color: #ffffff;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 10px 1px 10px;
  max-height: 60px;
  margin-left: 15px;
`;
const AllProgressbar = styled.div`
  width: 100%;
  display: flex;
  padding: 20px;
  border: 1px solid #c4c4c4;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.11);
  margin-top: 22px;
`;
const IndividualProgressBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
`;

const MixScoreCardPage = () => {
  const componentRef = useRef();

  const scoreData = [
    { currentScore: 75, maxScore: 100 },
    { currentScore: 50, maxScore: 100 },
    { currentScore: 90, maxScore: 100 },
  ];

  let handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  // const { userProfile } = useSelector(({ userProfile }: RootState) => ({
  //   userProfile,
  // }))
  //check Once yash
  const userProfile = useSelector((state) => state.profile);
  console.log(userProfile);
  const dispatch = useDispatch();
  const [testData, setData] = useState({});
  const [displayQuiz, setdisplayQuiz] = useState(false);
  const assessmentType = getQueryParams("type");
  useEffect(() => {
    const testId = getQueryParams("id");
    getTestData(assessmentType, testId);
  }, []);

  const getTestData = async (assessmentType, testId) => {
    dispatch({ type: AppConfigEvent.START_LOADING });
    try {
      //replace normal one with modified
      const res = await getModifiedAssessmentResult(assessmentType, testId);
      console.log("getTestData", res);
      const Mainresult = res.result;
      console.log("Mainresult", Mainresult);
      const resultType = Mainresult.type;
      console.log("resultType", resultType);
      const Quiz = resultType.QUIZ;
      console.log("Quiz", Quiz);
      const QuizAttemped = Quiz.attemptedQues;
      console.log("QuizAttemped", QuizAttemped);
      if (QuizAttemped !== 0) {
        setdisplayQuiz(true);
      }

      setData(res);
    } catch (err) {
      toast.error(`Unable to get your scorecard `, {
        position: "top-left",
      });
    } finally {
      dispatch({ type: AppConfigEvent.STOP_LOADING });
    }
  };
  const overallMarksPer =
    assessmentType === AssessmentType.APTITUDE
      ? (testData?.result?.rightAns / testData?.result?.totalQues) * 100
      : (testData?.result?.score / (testData?.result?.totalQues * 5)) * 100;
  const type =
    assessmentType === AssessmentType.APTITUDE
      ? testData?.result?.type
      : testData?.result?.type;
  const performanceOnPercentage = (percentage) => {
    if (percentage <= 30) return "Very Low";
    else if (percentage >= 31 && percentage <= 50) return "Low";
    else if (percentage >= 51 && percentage <= 70) return "Moderately High";
    return "High";
  };

  return (
    <>
      <Wrapper>
        {}

        <div className="container">
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6">
              <div style={{ float: "right" }}>
                <button onClick={handlePrint} className="e-btn">
                  Download PDF
                </button>
              </div>
            </div>
          </div>
        </div>
        <div ref={componentRef}>
          {displayQuiz === true ? (
            <>
              <div className="" style={{ padding: 40 }}>
                {type &&
                  Object.keys(type).length > 0 &&
                  Object.keys(type).map((key, index) => {
                    //const score = (type[key].score / type[key].totalQues) * 25;
                    const score = type[key].score;
                    const totalQues = type[key].totalQues;
                    const attemptedQues = type[key].attemptedQues;
                    return (
                      <>
                        <div className="row">
                          {ScoreCardConstants[key] === "Quiz" ? (
                            <>
                              <div className="row HeadText">
                                <div className="col-md-10 pt-15">
                                  Quiz Report
                                </div>
                                <div className="col-md-2">
                                  <img src="/assets/img/WhiteLabled/logo/logo.png" />
                                </div>
                              </div>
                              <div className="pt-20">
                                <p style={{ fontWeight: 600 }}>
                                  Name : {userProfile.firstName}&nbsp;
                                  {userProfile.lastName}
                                </p>
                                <p style={{ fontWeight: 600 }}>
                                  Total Number of Questions : {totalQues}
                                </p>
                                <p style={{ fontWeight: 600 }}>
                                  Number of Attemped Questions : {attemptedQues}
                                </p>
                                <p style={{ fontWeight: 600 }}>
                                  Total Number of Right Answer : {score}
                                </p>
                                <p style={{ fontWeight: 600 }}>
                                  Your Score: {score}/{totalQues}
                                  {/* <ScoreBar currentScore={score} maxScore={20} /> */}
                                </p>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </>
                    );
                  })}
              </div>
            </>
          ) : (
            <>
              <ProgressContainer>
                <div className="row HeadText">
                  <div className="col-md-10">Personality Test</div>
                  <div className="col-md-2">
                    <img src="/assets/img/logo/logo.svg" />
                  </div>
                </div>
              </ProgressContainer>
              <div className="col-md-12">
                <div className="text-container">
                  <p className="sub-title-text">
                    The test you have just taken measures the following five
                    personality traits:
                    <p className="main-name">1. Extraversion</p>
                    <p className="main-name">2. Altruism</p>
                    <p className="main-name">3. Emotional Composure</p>
                    <p className="main-name">4. Orderliness</p>
                    <p className="main-name">5. Openness</p>
                  </p>
                  <p className="pt-20 sub-description-text">
                    Together, your scores on these traits present a good view of
                    you as a person. Below, you can read how you score on each
                    of these traits.
                  </p>
                  <p className="score-title pt-20">TOTAL SCORE</p>
                  <p className="sub-description-text">
                    Below, you can see how you've scored on all five personality
                    traits.
                  </p>
                  <div className="pt-40">
                    {type &&
                      Object.keys(type).length > 0 &&
                      Object.keys(type).map((key, index) => {
                        const score = type[key].score;
                        return (
                          <div className="row">
                            <div className="col-md-3">
                              <SpanText
                                fontWeight={600}
                                paddLeft={22}
                                fontSize={19}
                                color={
                                  index === 0
                                    ? "#5A974F"
                                    : index === 1
                                    ? "#538CD5"
                                    : index === 2
                                    ? "#953734"
                                    : "#595959"
                                }
                              >
                                {ScoreCardConstants[key]}:
                              </SpanText>
                            </div>
                            <div className="col-md-9">
                              <ScoreBar currentScore={score} maxScore={20} />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <ul className="pt-30">
                    <li className="score-li pt-10">
                      Keep in mind that there is no good or bad score. For some
                      jobs or careers, a high score is desirable, while a low
                      score is more suitable for others. Always look for a work
                      environment that capitalizes on the unique strengths of
                      your personality. This will allow you to be as true to
                      yourself as possible and will enable you to engage in the
                      kinds of activities that suit you best. Playing to your
                      strengths will result in a win-win situation for everyone
                      involved.
                    </li>
                    <li className="score-li pt-15">
                      Are you currently pursuing a career that doesn't
                      completely match your personality? Do not worry. Discuss
                      with your coach how you can learn to handle those aspects
                      of your job / career that are difficult for you.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="SoftSkillsContainer">
                <div className="" style={{ padding: 40 }}>
                  {type &&
                    Object.keys(type).length > 0 &&
                    Object.keys(type).map((key, index) => {
                      //const score = (type[key].score / type[key].totalQues) * 25;
                      const score = type[key].score;

                      return (
                        <>
                          <div className="row">
                            {ScoreCardConstants[key] === "Extraversion" ? (
                              <>
                                <div className="row main-row-container">
                                  <p>
                                    <span className="span-heading">
                                      EXTRAVERSION
                                    </span>
                                    <br />
                                    <span className="span-color-text-green">
                                      The bar shows how extraverted you are. The
                                      more to the right your score is, the
                                      higher you score on extraversion.
                                    </span>
                                  </p>

                                  <div className="row pt-40">
                                    <div className="row">
                                      <div className="col-md-3">
                                        <p
                                          style={{ float: "right" }}
                                          className="bar-text"
                                        >
                                          Introvert
                                        </p>
                                      </div>
                                      <div className="col-md-6">
                                        <div style={{ marginTop: 6 }}>
                                          <ScoreBar
                                            currentScore={score}
                                            maxScore={20}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <p className="bar-text">Extrovert</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="pt-40">
                                    <ul>
                                      <li className="score-li">
                                        The more to the right your score is, the
                                        more extrovert you are. That means that
                                        you enjoy being among people, you like
                                        to talk, you're usually cheerful and
                                        spontaneous and you need adventure.
                                      </li>
                                      <li className="score-li pt-5">
                                        The more to the left your score is, the
                                        more introvert you are. That means that
                                        you like to think, have no problem being
                                        alone and that you tend to stay out of
                                        the limelight.
                                      </li>
                                    </ul>
                                  </div>
                                  <p className="p-subtitle pt-40">
                                    What suits you in your work and profession?
                                  </p>
                                  <ul>
                                    <li className="score-li pt-10">
                                      <b>Extroverts</b> enjoy work that
                                      challenges them and allows them to
                                      interact with people. The work should not
                                      be boring. Examples of sectors in which
                                      extroverts fit well are marketing, sales
                                      and entertainment. Few suitable careers
                                      include
                                      <b>
                                        {" "}
                                        Sales Executive, Business Relationship
                                        Manager, Public Relations, Event
                                        Managers{" "}
                                      </b>
                                      and <b>HR Recruiters</b>
                                    </li>
                                    <li className="score-li pt-5">
                                      <b>Introverts</b> enjoy work where they
                                      can be very independent, do their own
                                      thing and do not require too much
                                      interaction. Examples of sectors that suit
                                      introverts are literature, science, and
                                      technology. Few suitable careers include{" "}
                                      <b>
                                        Technical Writer, Software Developer,
                                        Graphic Designer, Accountant, Web
                                        Developer, Architect, Process Analyst
                                      </b>
                                    </li>
                                  </ul>
                                  <p className="p-subtitle pt-40">Tips!</p>
                                  <ul>
                                    <li className="score-li pt-10">
                                      Do you have a high score on extraversion?
                                      Talking is important for good contact with
                                      people, and therefore also for
                                      collaboration. However, it’s important not
                                      to get carried away
                                    </li>
                                    <li className="score-li pt-5">
                                      Do you have a high score on introversion?
                                      Do not unnecessarily avoid contact with
                                      people at work. Even though you are very
                                      independent, you sometimes need others to
                                      excel
                                    </li>
                                  </ul>
                                </div>
                              </>
                            ) : null}
                            {ScoreCardConstants[key] === "Altruism" ? (
                              <>
                                <div
                                  style={{ marginTop: 50 }}
                                  className="row main-row-container"
                                >
                                  <p>
                                    <span className="span-heading">
                                      ALTRUISM
                                    </span>
                                    <br />
                                    <span className="span-color-text-green">
                                      The bar shows how altruistic you are
                                      according to the test. The more to the
                                      right your score is, the higher you score
                                      on altruism.
                                    </span>
                                  </p>

                                  <div className="row pt-40">
                                    <div className="row">
                                      <div className="col-md-3">
                                        <p
                                          style={{ float: "right" }}
                                          className="bar-text"
                                        >
                                          Competitive
                                        </p>
                                      </div>
                                      <div className="col-md-6">
                                        <div style={{ marginTop: 6 }}>
                                          <ScoreBar
                                            currentScore={score}
                                            maxScore={20}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <p className="bar-text">Altruistic</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="pt-40">
                                    <ul>
                                      <li className="score-li">
                                        The more to the right your score is, the
                                        more friendly and compliant you are.
                                        That means that you like to help others,
                                        take others' feelings into account and
                                        that you value harmony in your
                                        relationships with others to be
                                        important.
                                      </li>
                                      <li className="score-li pt-5">
                                        The more to the left your score is, the
                                        more competitive you are. Perhaps
                                        unintentionally so, you think more about
                                        your self-interest and don't always take
                                        others into account. You can also be
                                        direct, impatient, or stubborn.
                                      </li>
                                    </ul>
                                  </div>
                                  <p className="p-subtitle pt-40">
                                    What suits you in your work and profession?
                                  </p>
                                  <ul>
                                    <li className="score-li pt-10">
                                      <b>Altruisitic</b> people enjoy work where
                                      they can make a difference to others.
                                      Examples of sectors that appeal to
                                      altruistic people are healthcare and
                                      education. Few suitable careers include{" "}
                                      <b>
                                        nurse, teacher, social worker, hospital
                                        staff, customer service
                                      </b>{" "}
                                      and <b>hospitality staff.</b>
                                    </li>
                                    <li className="score-li pt-5">
                                      <b>Competitive</b> people with a lower
                                      score on altruism enjoy work where they
                                      can realise their own goals and ambitions.
                                      They are good at taking unpopular measures
                                      and work well in highly competitive
                                      environments. Examples of sectors in which
                                      these people function well are management,
                                      law enforcement and sales. Few suitable
                                      careers include{" "}
                                      <b>
                                        branch manager, sales manager, business
                                        owner
                                      </b>{" "}
                                      and <b>lawyer</b>
                                    </li>
                                  </ul>
                                  <p className="p-subtitle pt-40">Tips!</p>
                                  <ul>
                                    <li className="score-li pt-10">
                                      Do you have a high score on altruism? Be
                                      careful that you don't focus solely on
                                      helping others at your own expense. Don't
                                      be afraid to say 'no'. After all, you can
                                      only do something for another when you
                                      first take care of yourself.
                                    </li>
                                    <li className="score-li pt-5">
                                      Do you have a low score on altruism?
                                      Realize that other people can be very
                                      helpful if you treat them well. If you do
                                      something for others, they will do
                                      something for you when you need them.
                                    </li>
                                  </ul>
                                </div>
                              </>
                            ) : null}

                            {ScoreCardConstants[key] ===
                            "Emotional composure" ? (
                              <>
                                <div
                                  style={{ marginTop: 50 }}
                                  className="row main-row-container page-section-breaker"
                                >
                                  <p>
                                    <span className="span-heading">
                                      EMOTIONAL COMPOSURE
                                    </span>
                                    <br />
                                    <span className="span-color-text-green">
                                      The bar shows how emotionally composed you
                                      are according to the test. The more to the
                                      right your score is, the higher you score
                                      on emotional composure.
                                    </span>
                                  </p>

                                  <div className="row pt-40">
                                    <div className="row">
                                      <div className="col-md-3">
                                        <p
                                          style={{ float: "right" }}
                                          className="bar-text"
                                        >
                                          Worried easily
                                        </p>
                                      </div>
                                      <div className="col-md-6">
                                        <div style={{ marginTop: 6 }}>
                                          <ScoreBar
                                            currentScore={score}
                                            maxScore={20}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <p className="bar-text">
                                          Emotionally composed
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="pt-40">
                                    <ul>
                                      <li className="score-li">
                                        The more to the right your score is, the
                                        more emotionally composed you are. This
                                        means that you are usually calm, strong
                                        and resilient. You don't succumb to
                                        stress easily.
                                      </li>
                                      <li className="score-li pt-5">
                                        The more to the left your score is, the
                                        more worried you are. You are sensitive
                                        in nature, experience emotional ups and
                                        downs and suffer from insecurity and
                                        stress.
                                      </li>
                                    </ul>
                                  </div>
                                  <p className="p-subtitle pt-40">
                                    What suits you in your work and profession?
                                  </p>
                                  <ul>
                                    <li className="score-li">
                                      <b>Emotionally composed</b> people can do
                                      many different kinds of work. A high score
                                      on emotional stability is particularly
                                      useful in sectors and professions that
                                      come with lots of risks, stress and entail
                                      great responsibility. Examples of these
                                      sectors are the military, management
                                      positions, aviation, sports and
                                      healthcare. Few suitable careers include
                                      <b>
                                        {" "}
                                        police officer, surgeon, doctor, general
                                        manager,{" "}
                                      </b>
                                      and <b>pilot.</b>
                                    </li>
                                    <li className="score-li pt-5">
                                      People who score{" "}
                                      <b>lower on emotional composure</b> enjoy
                                      work they can do at their own pace, work
                                      that doesn't put too much pressure on
                                      them, where it is still important to be
                                      alert and attentive, but where sensitivity
                                      is more valuable than stability under
                                      pressure. Examples of sectors where you
                                      must be sensitive and alert are sectors
                                      such as service provision, maintenance and
                                      administration. Few suitable careers
                                      include
                                      <b>
                                        {" "}
                                        Quality Assurance, Technician, customer
                                        service{" "}
                                      </b>
                                      and <b>back end operations</b>
                                    </li>
                                  </ul>
                                  <p className="p-subtitle pt-40">Tips!</p>
                                  <ul>
                                    <li className="score-li">
                                      Do you have a high score on emotional
                                      composure? Always be patient with people
                                      who get stressed easily. Not everyone is
                                      as emotionally sturdy as you!
                                    </li>
                                    <li className="score-li pt-5">
                                      Do you have a low score on emotional
                                      composure? Discuss with your coach what
                                      techniques you can learn to better cope
                                      with stress.
                                    </li>
                                  </ul>
                                </div>
                              </>
                            ) : null}
                            {ScoreCardConstants[key] === "Orderliness" ? (
                              <>
                                <div
                                  style={{ marginTop: 50 }}
                                  className="row main-row-container page-section-breaker"
                                >
                                  <p>
                                    <span className="span-heading">
                                      ORDERLINESS
                                    </span>
                                    <br />
                                    <span className="span-color-text-green">
                                      The bar shows how orderly you are
                                      according to the test. The more to the
                                      right your score is, the higher you score
                                      on orderliness.
                                    </span>
                                  </p>

                                  <div className="row pt-40">
                                    <div className="row">
                                      <div className="col-md-3">
                                        <p
                                          style={{ float: "right" }}
                                          className="bar-text"
                                        >
                                          Spontaneous
                                        </p>
                                      </div>
                                      <div className="col-md-6">
                                        <div style={{ marginTop: 6 }}>
                                          <ScoreBar
                                            currentScore={score}
                                            maxScore={20}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <p className="bar-text">Orderly</p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="pt-40">
                                    <ul>
                                      <li className="score-li">
                                        The more to the right your score is, the
                                        more organized and focused you are. This
                                        means that you are extremely planned,
                                        work systematically and that you love
                                        order and structure. You work hard, get
                                        things done and you've got an eye for
                                        detail.
                                      </li>
                                      <li className="score-li pt-5">
                                        The more to the left your score is, the
                                        more spontaneous and less structured you
                                        are. You live day by day and are
                                        flexible in how you approach your work.
                                        You can be sometimes messy and chaotic.
                                      </li>
                                    </ul>
                                  </div>
                                  <p className="p-subtitle pt-40">
                                    What suits you in your work and profession?
                                  </p>
                                  <ul>
                                    <li className="score-li">
                                      <b>Orderly</b> people can do many
                                      different kinds of work. A high score on
                                      orderliness is particularly helpful in
                                      sectors and professions where accuracy is
                                      important, and where mistakes have
                                      unfortunate consequences. Examples are the
                                      engineering, research, insurance
                                      underwriting and administrative sectors.
                                      Few suitable careers include{" "}
                                      <b>
                                        data analysts, design engineer,
                                        underwriters.
                                      </b>
                                    </li>
                                    <li className="score-li pt-5">
                                      Spontaneous people who score{" "}
                                      <b>lower on orderliness</b> like variation
                                      in their work and can improvise well. This
                                      can be helpful in a sector such as arts or
                                      in positions where flexibility is very
                                      important. Few suitable careers include{" "}
                                      <b>
                                        photographer, designers, writers, or
                                        advertisement agencies
                                      </b>
                                    </li>
                                  </ul>
                                  <p className="p-subtitle pt-40">Tips!</p>
                                  <ul>
                                    <li className="score-li">
                                      Do you have a high score on orderliness?
                                      Be mindful that you're not too much of a
                                      perfectionist, as this will only lead to
                                      unnecessary stress. Usually, 'good' really
                                      is good enough and perfection is overkill.
                                    </li>
                                    <li className="score-li pt-5">
                                      Do you have a low score on orderliness?
                                      Just look at what proper planning can do
                                      for you. Learn the art of time management,
                                      goal setting, and prioritization from your
                                      coach.
                                    </li>
                                  </ul>
                                </div>
                              </>
                            ) : null}
                            {ScoreCardConstants[key] === "Openness" ? (
                              <>
                                <div
                                  style={{ marginTop: 50 }}
                                  className="row main-row-container page-section-breaker-open"
                                >
                                  <p>
                                    <span className="span-heading">
                                      OPENNESS
                                    </span>
                                    <span className="span-color-text-green">
                                      <br />
                                      The bar shows how open you are to new
                                      experiences. The more to the right your
                                      score is, the higher you score on openness
                                      to experience.
                                    </span>
                                  </p>

                                  <div className="row pt-40">
                                    <div className="row">
                                      <div className="col-md-3">
                                        <p
                                          style={{ float: "right" }}
                                          className="bar-text"
                                        >
                                          More Traditional
                                        </p>
                                      </div>
                                      <div className="col-md-6">
                                        <div style={{ marginTop: 6 }}>
                                          <ScoreBar
                                            currentScore={score}
                                            maxScore={20}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <p className="bar-text">Open</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="pt-40">
                                    <ul>
                                      <li className="score-li">
                                        The more to the right your score is, the
                                        more open you are to new experiences.
                                        This means that you are curious and
                                        open-minded, willing to try new ideas,
                                        and that you have lots of ideas and an
                                        active imagination.
                                      </li>
                                      <li className="score-li">
                                        The more to the left your score is, the
                                        more traditionally minded and less open
                                        to new experiences you are. You prefer
                                        the familiar, the following of routines.
                                        You are a sober person that doesn’t like
                                        change.
                                      </li>
                                    </ul>
                                  </div>
                                  <p className="p-subtitle pt-40">
                                    What suits you in your work and profession?
                                  </p>
                                  <ul>
                                    <li className="score-li">
                                      People who are <b>open</b> to new
                                      experiences like to do work that enables
                                      them to experience new things, be more
                                      philosophical or more inventive. Their
                                      creativity means that they can work in a
                                      lot of sectors and functions. Think of
                                      entrepreneurs who have a new idea or start
                                      a new company, and of professions such as
                                      journalism or architecture. Other examples
                                      of professions that benefit from a high
                                      score of this trait are{" "}
                                      <b>
                                        illustrator, interior designer, brand
                                        manager, actor and artist.
                                      </b>
                                    </li>
                                    <li className="score-li pt-5">
                                      People who score <b>lower on openness </b>
                                      like predictability in their work and are
                                      more traditional. This is helpful in
                                      sectors where it's important that you work
                                      according to the existing rules and
                                      procedures. For example, the financial
                                      sector, administrative sector, accounting
                                      and audit. Few suitable careers include{" "}
                                      <b>
                                        financial controller, process operator,
                                        paralegal, and auditor.
                                      </b>
                                    </li>
                                  </ul>
                                  <p className="p-subtitle pt-40">Tips!</p>
                                  <ul>
                                    <li className="score-li">
                                      Do you have a high score on openness? Make
                                      your ideas concrete: who, what, how and
                                      when should something happen? Otherwise,
                                      you might get stuck in your daydreams and
                                      fantasies.
                                    </li>
                                    <li className="score-li pt-5">
                                      Do you have a low score on openness? Don't
                                      be afraid to change. Realize that you can
                                      get used to new things if you give
                                      yourself time to warm up to them.
                                    </li>
                                  </ul>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </>
          )}
        </div>
      </Wrapper>
    </>
  );
};
export default MixScoreCardPage;
