const BulletList = [];

const WhiteTestimonial = () => {
  return (
    <>
      <section
        className="testimonial__area pt-50 pb-50"
        style={{
          background: `url(/assets/img/shape/testimonial-bg-3.jpg)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="text-center">
          <h2 style={{ color: "white" }}>How NIMO Labs can help you?</h2>
        </div>
        <div className="container">
          <div className="row">
            <div class="container mt-5">
              <div className="text-center">
                <p style={{ color: "white" }}>
                  Through our experienced industry mentors and smart AI Tools,
                  we provide each student the three most important aspects for
                  achieving an outstanding career:
                </p>
              </div>
              <div className="text-center pt-20">
                <p style={{ color: "white", fontWeight: 600 }}>
                  Build a strong profile
                </p>
                <p style={{ color: "white", fontWeight: 600 }}>
                  Upskill to become interview-ready
                </p>
                <p style={{ color: "white", fontWeight: 600 }}>
                  Find the right job with the click of a button
                </p>
              </div>
              <div class="row">
                <div class="col-md-4 mb-4">
                  <div style={{ height: 250 }} class="card">
                    <div class="card-body">
                      <h5 class="card-title">1-on-1 & group Coaching </h5>
                      <p class="card-text">
                        We help students & professionals with 1 on 1 coaching on
                        specific personal issues, group coaching on behaviour
                        skills training and AI tool based assessment framework
                        for getting faster results.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-4">
                  <div style={{ height: 250 }} class="card">
                    <div class="card-body">
                      <h5 class="card-title">Personality Development</h5>
                      <p class="card-text">
                        Personality is a collective presentation of personal
                        character traits. Our programs help develop social
                        skills, potential, attitudes etc. that makes a person
                        distinctive.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-4">
                  <div style={{ height: 250 }} class="card">
                    <div class="card-body">
                      <h5 class="card-title">Confidence Building</h5>
                      <p class="card-text">
                        Confidence is a state of mind which comes from feelings,
                        acceptance and belief in your own ability. Simple &
                        effective techniques and advice will help boost
                        self-confidence.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div class="container mt-5">
              <div class="row">
                <div class="col-md-4 mb-4">
                  <div style={{ height: 250 }} class="card">
                    <div class="card-body">
                      <h5 class="card-title">Personal Branding</h5>
                      <p class="card-text">
                        Groom yourself and learn techniques to create a strong
                        impression with your stakeholders
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-4">
                  <div style={{ height: 250 }} class="card">
                    <div class="card-body">
                      <h5 class="card-title">Interview Preparation</h5>
                      <p class="card-text">
                        <ul>
                          <li>• Mock Interview</li>
                          <li>• AI Interview</li>
                          <li>• Profile building </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-4">
                  <div style={{ height: 250 }} class="card">
                    <div class="card-body">
                      <h5 class="card-title">Assessment & Evaluation </h5>
                      <p class="card-text">
                        <ul>
                          <li>• Soft Skills Assessment </li>
                          <li>• Personality Assessment</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhiteTestimonial;
