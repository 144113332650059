import Carousel from "react-multi-carousel";
import { useState, useEffect } from "react";
import { GetStudentDatafromMaster } from "../../services/jobs-service";
import {
  getDataFromLocalStorage,
  setDataInLocalStorage,
} from "../../browser-storage";
import ModelHelper from "./ModelHelper";
import { Link } from "react-router-dom";
import { responsive } from "./slidermenuData";
//import IconContainer from "./IconContainer";
const SliderMenu = () => {
  const [isYoutubeModalOpen, setIsYoutubeModalOpen] = useState(false);

  const [userMasterData, setuserMasterData] = useState({
    isReferredbyMentor: "false",
    IsUserPremium: "false",
    IsUserPremium_CB: "false",
  });

  const [getReferredMentorEmailId, setgetReferredMentorEmailId] = useState({
    ReferredMentorEmailId: "",
  });

  useEffect(() => {
    getStudentDataFromMasterTable();
  }, []);

  const getStudentDataFromMasterTable = async () => {
    const userDetials = getDataFromLocalStorage("user-details");
    console.log(userDetials);
    const currentrole = userDetials.role;
    const currentemail = userDetials.email;
    const resp = await GetStudentDatafromMaster(currentemail)
      .then((res) => {
        const userdata = res[0];
        const getIsReferredbyMentor = res[0].isReferredbyMentor;
        const ReferredbyMentorEmailId = res[0].ReferredbyMentorEmailId;
        const IsStudentPremium = res[0].IsUserPremium;
        const IsUserPremium_CB = res[0].IsUserPremium_CB;
        const IsUserPremium_AI = res[0].IsUserPremium_AI;
        const IsUserPremium_IP = res[0].IsUserPremium_IP;
        const IsUserPremium_ASS_SOFT = res[0].IsUserPremium_ASS_SOFT;
        const IsUserPremium_ASS_APTI = res[0].IsUserPremium_ASS_APTI;
        const IsUserPremium_RB = res[0].IsUserPremium_RB;
        const IsUserPremium_G1 = res[0].IsUserPremium_G1;
        console.log("getIsReferredbyMentor", getIsReferredbyMentor);
        console.log("ReferredbyMentorEmailId", ReferredbyMentorEmailId);
        setuserMasterData({
          isReferredbyMentor: getIsReferredbyMentor,
          IsUserPremium: IsStudentPremium,
          IsUserPremium_CB: IsUserPremium_CB,
          IsUserPremium_AI: IsUserPremium_AI,
          IsUserPremium_IP: IsUserPremium_IP,
          IsUserPremium_ASS_SOFT: IsUserPremium_ASS_SOFT,
          IsUserPremium_ASS_APTI: IsUserPremium_ASS_APTI,
          IsUserPremium_RB: IsUserPremium_RB,
          IsUserPremium_G1: IsUserPremium_G1,
        });
        setDataInLocalStorage(
          "getReferredMentorEmailId",
          ReferredbyMentorEmailId
        );
        setgetReferredMentorEmailId({
          getReferredMentorEmailId: ReferredbyMentorEmailId,
        });
        console.log(userdata);
      })
      .catch((err) => {
        console.log(err);
      });
    //console.log(userMasterData);
  };
  const ModelHandler = (item) => {
    setIsYoutubeModalOpen(true);
    setcurrentvideoCode(item);
    //console.log("currentvideoCode", currentvideoCode);
  };

  const [currentvideoCode, setcurrentvideoCode] = useState("");

  const MenuDB = [
    {
      menu_title: "Branding",
      menu_link: "/personal-branding",
      menu_icon_link: "/assets/img/ghimages/graphic-icons/gjob1.svg",
      menu_icon_alt: "Jobs Icon",
      menu_conditon: `${
        userMasterData.IsUserPremium == "true"
        //&& userMasterData.IsUserPremium_RB == "true"
      }`,
    },
    {
      menu_title: "Communication",
      menu_link: "/art-of-communication",
      menu_icon_link: "/assets/img/ghimages/graphic-icons/ggdtriangle.svg",
      menu_icon_alt: "Jobs Icon",
      menu_conditon: `${
        userMasterData.IsUserPremium == "true" //&& userMasterData.IsUserPremium_RB == "true"
      }`,
    },
    {
      menu_title: "Interview Prep",
      menu_link: "/interview-preparation",
      menu_icon_link: "/assets/img/ghimages/graphic-icons/interview.svg",
      menu_icon_alt: "Jobs Icon",

      menu_conditon: `${
        userMasterData.IsUserPremium == "true" //&& userMasterData.IsUserPremium_RB == "true"
      }`,
    },
    {
      menu_title: "Mentor Course",
      menu_link: "/mentor-course",
      menu_icon_link: "/assets/img/ghimages/graphic-icons/speedmentoring.svg",
      menu_icon_alt: "Jobs Icon",

      menu_conditon: `${userMasterData.IsUserPremium == "true"}`,
    },
    {
      menu_title: "Interpersonal",
      menu_link: "/behavioral-available-test",
      menu_icon_link: "/assets/img/ghimages/graphic-icons/thinking.svg",
      menu_icon_alt: "Jobs Icon",

      menu_conditon: `${
        userMasterData.IsUserPremium == "true" //&& userMasterData.IsUserPremium_ASS_SOFT == "true"
      }`,
    },
    // {
    //   menu_title: "MBTI",
    //   menu_link: "/student-dashboard",
    //   menu_icon_link: "/assets/img/ghimages/graphic-icons/brain.svg",
    //   menu_icon_alt: "Jobs Icon",

    //   menu_conditon: `${
    //     userMasterData.IsUserPremium == "true" //&&  userMasterData.IsUserPremium_MBTI == "true"
    //   }`,
    // },
    {
      menu_title: "Aptitude",
      menu_link: "/available-test",
      menu_icon_link:
        "/assets/img/ghimages/graphic-icons/online-examination.svg",
      menu_icon_alt: "Jobs Icon",

      menu_conditon: `${
        userMasterData.IsUserPremium == "true" //&&    userMasterData.IsUserPremium_ASS_APTI == "true"
      }`,
    },
    {
      menu_title: "Resume",
      menu_link: "/resume-builder",
      menu_icon_link: "/assets/img/ghimages/graphic-icons/resumeicon.svg",
      menu_icon_alt: "Jobs Icon",

      menu_conditon: `${
        userMasterData.IsUserPremium == "true" //&&        userMasterData.IsUserPremium_RB == "true"
      }`,
    },
    {
      menu_title: "AI Interview",
      menu_link: "/dashboard/quick-interview",
      menu_icon_link: "/assets/img/ghimages/graphic-icons/gai1.svg",
      menu_icon_alt: "Jobs Icon",

      menu_conditon: `${
        userMasterData.IsUserPremium == "true" //&&       userMasterData.IsUserPremium_AI == "true"
      }`,
    },
    // {
    //   menu_title: "Jobs",
    //   menu_link: "/jobs",
    //   menu_icon_link: "/assets/img/ghimages/graphic-icons/gjob2.svg",
    //   menu_icon_alt: "Jobs Icon",

    //   menu_conditon: `${userMasterData.IsUserPremium == "true"}`,
    // },
  ];

  return (
    <>
      {isYoutubeModalOpen && (
        <ModelHelper
          CurrentYoutubevideoCode={currentvideoCode}
          closeAction={() => setIsYoutubeModalOpen(false)}
        />
      )}
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={true}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={false}
        autoPlay={false}
        autoPlaySpeed={4000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {MenuDB.map((item) => {
          return (
            <div className="icons-container">
              {item.menu_conditon === "true" ? (
                <>
                  <Link to={item.menu_link} state={{ data: item.menu_state }}>
                    <img
                      width="100px"
                      src={item.menu_icon_link}
                      alt={item.menu_icon_alt}
                    />
                    <p style={{ color: "#2b4eff", fontWeight: 600 }}>
                      {item.menu_title}
                    </p>
                  </Link>
                </>
              ) : (
                <>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => ModelHandler("Premium")}
                  >
                    <img
                      width="100px"
                      src={item.menu_icon_link}
                      alt={item.menu_icon_alt}
                    />
                    <p>{item.menu_title}</p>
                    <p style={{ color: "#2b4eff" }}>
                      <img
                        width="20px"
                        src="/assets/img/ghimages/graphic-icons/padlock2.svg"
                        alt="Lock Icon"
                      />
                      &nbsp;Premium
                    </p>
                  </div>
                </>
              )}
            </div>
          );
        })}
      </Carousel>
    </>
  );
};

export default SliderMenu;
