const about_info = {
  reviews: "8,200+",
  about_name: "Depikka Siingh",
  about_img: "/assets/img/WhiteLabled/about/1.jpg",
  about_banner: "/assets/img/about/about-banner.jpg",
  students_imgs: [
    "/assets/img/about/student-4.jpg",
    "/assets/img/about/student-3.jpg",
    "/assets/img/about/student-2.jpg",
    "/assets/img/about/student-1.jpg",
  ],
  students: "",
  title: <>{/* Achieve your <br /> */}</>,
  shape_text: "",
  title_2: "About Us",
  desc: "I'm Maanu Bhandari, your guide on an exciting journey of learning, growth, and personal transformation. With a remarkable 25-year career as a Learning and Development Specialist, I've had the privilege of working with a diverse range of clients who have experienced the power of education in new and impactful ways. ",
  sub_desc: "",
  about_list: [
    "At  Destiny Planners , we are dedicated to empowering the next generation of talent by equipping students with the essential skills and knowledge needed to excel in the competitive job market. We understand that the journey from education to employment can be challenging, and that's where we come in with Campus to Corporate program.",
    "Destiny Planners is well equipped with Life Coach, Yogesh Baheti, who is our Founder .  He is well known International Career Counsellor,Parenting Coach, Soft skills, Life skills and a Corporate behavioural trainer and a performance coach for the past 25+ years, focused on enhancing the learning process for better performance. ",
    "Our mission is to bring out the best in every individual who is willing to change and succeed in life",
  ],
};

const WhiteAboutArea = ({ padd = "pt-90 pb-90" }) => {
  return (
    <>
      <section style={{ paddingBottom: 50 }} className={`about__area ${padd}`}>
        <div className="container">
          <div className="row text-center">
            <div className="col-md-4">
              <div className="pt-100">
                <img
                  width={400}
                  height={400}
                  style={{ borderRadius: "50%" }}
                  src={about_info.about_img}
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="about__content pl-70 pr-60 pt-25">
                <div className="section__title-wrapper mb-25">
                  <h2 style={{ fontSize: 36 }} className="section__title">
                    {about_info.title}
                    <span className="yellow-bg-big">
                      {about_info.shape_text}{" "}
                      <img src="/assets/img/shape/yellow-bg-2.png" alt="" />
                    </span>{" "}
                    {about_info.title_2}{" "}
                  </h2>

                  {/* <p style={{ textAlign: "left" }}>
                    {about_info.about_list.map((item) => {
                      return (
                        <>
                          <p
                            className="pt-10"
                            style={{ fontSize: 18, fontWeight: 400 }}
                          >
                            {item}
                          </p>
                        </>
                      );
                    })}
                  </p> */}
                  <p className="pt-10" style={{ textAlign: "left" }}>
                    At NIMO Labs, we are dedicated to empowering the next
                    generation of talent by equipping students with the
                    essential skills and knowledge needed to excel in the
                    competitive job market. We understand that the journey from
                    education to employment can be challenging, and that's where
                    we come in with <b>Campus to Corporate program</b>.
                  </p>
                  <p className="pt-10" style={{ textAlign: "left" }}>
                    Our mission is to provide comprehensive, end-to-end support
                    to students, helping them bridge the gap between academia
                    and the professional world. We are committed to preparing
                    students for job opportunities, interviews, and career
                    success through a holistic approach that combines education,
                    training, and mentorship.
                  </p>
                  <p className="pt-10" style={{ textAlign: "left" }}>
                    Destiny Planners is well equipped with{" "}
                    <b>Life Coach, Mr. Mohit Maheshwari</b>, who is our Founder
                    . He is well known International Career Counsellor,Parenting
                    Coach, Soft skills, Life skills and a Corporate behavioural
                    trainer and a performance coach for the past 25+ years,
                    focused on enhancing the learning process for better
                    performance.{" "}
                  </p>
                  <p className="pt-10" style={{ textAlign: "left" }}>
                    <b>
                      Our mission is to bring out the best in every individual
                      who is willing to change and succeed in life.
                    </b>
                  </p>
                  <p style={{ textAlign: "left" }} className="pt-20">
                    {about_info.sub_desc}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhiteAboutArea;
