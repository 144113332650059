import Wrapper from "../../layout/wrapper";
import Pricing from "../home/pricing";
import WhiteAboutArea from "./white-about-area";
import WhiteCompanyCarousel from "./white-company-carousel";
import WhiteCta from "./white-cta";
import WhiteHeader from "./white-header";
import WhiteHeroSlider from "./white-hero-slider";
import WhiteImageGallery from "./white-image-gallery";
import WhitePricing from "./white-pricing";
import WhitePricingSingle from "./white-pricing-single";
import WhiteTestimonial from "./white-testimonial";

const WhiteLabledHome = () => {
  return (
    <>
      <Wrapper>
        <WhiteHeroSlider />
        <WhiteAboutArea />
        {/* <WhiteCompanyCarousel /> */}
        {/* 
      <WhiteImageGallery /> 
      <WhitePricing />*/}
        {/* <WhitePricingSingle /> */}
        <WhiteTestimonial />
        <Pricing />
        <WhiteCta />
      </Wrapper>
    </>
  );
};

export default WhiteLabledHome;
