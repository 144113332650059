import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../components/common/sidebar";
import useSticky from "../../hooks/use-sticky";
import { menu_bar } from "../../redux/features/header-slice";
import NavMenu from "./nav-menu";

const Header = ({ header_white, header_shadow }) => {
  const { sticky } = useSticky();
  const dispatch = useDispatch();

  const [currentRole, setcurrentRole] = useState("");
  const navigate = useNavigate();

  const _user = localStorage.getItem("user");
  useEffect(() => {
    if (_user) {
      const user = JSON.parse(_user);
      const currentRoletemp = JSON.stringify(user.role);
      setcurrentRole(currentRoletemp);
    }
  }, []);

  const handleLogout = () => {
    console.log("button clicked");
    localStorage.removeItem("user");
    localStorage.removeItem("user-details");
    setcurrentRole("");
    navigate("/student-login", { replace: true });
  };

  return (
    <>
      <header>
        <div
          id="header-sticky"
          className={`header__area 
        ${
          header_shadow
            ? "header__padding-2 header__shadow"
            : "header__transparent header__padding"
        } 
        ${header_white ? "header__white" : ""} ${sticky ? "sticky" : ""}`}
        >
          <div className={`${header_shadow ? "container" : "container-fluid"}`}>
            <div className="row align-items-center">
              <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-2 col-sm-4 col-6">
                <div className="header__left d-flex">
                  <div className="logo">
                    <Link to="/">
                      <img
                        src="/assets/img/WhiteLabled/logo/logo.png"
                        alt="logo"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-10 col-sm-8 col-6">
                <div className="header__right d-flex justify-content-end align-items-center">
                  <div
                    className={`${
                      header_white
                        ? "main-menu main-menu-3 d-none d-xl-block"
                        : header_shadow
                        ? "main-menu main-menu-2 d-none d-xl-block"
                        : "main-menu d-none d-xl-block"
                    }`}
                  >
                    <nav id="mobile-menu">
                      {/* NavMenu start */}
                      <NavMenu />
                      {/* NavMenu end */}
                    </nav>
                  </div>

                  {header_shadow && (
                    <div className="header__btn header__btn-2 ml-50 d-none d-sm-block">
                      {currentRole == "" ? (
                        <Link to="/student-login" className="e-btn">
                          Sign in
                        </Link>
                      ) : null}

                      {currentRole !== "" ? (
                        <div onClick={() => handleLogout()} className="e-btn">
                          Logout
                        </div>
                      ) : null}
                    </div>
                  )}
                  <div className="sidebar__menu d-xl-none">
                    <div
                      onClick={() => dispatch(menu_bar(true))}
                      className="sidebar-toggle-btn ml-30"
                      id="sidebar-toggle"
                    >
                      <span className="line"></span>
                      <span className="line"></span>
                      <span className="line"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* sidebar start */}
      <Sidebar />
      {/* sidebar end */}
    </>
  );
};

export default Header;
