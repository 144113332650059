export const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 8,
    slidesToSlide: 8, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const LocalInterviewTipsDB = {
  course_mentor_profileImgUrl: "",
  course_created_by_our_mentor: "",
  userEmailId: "",
  _id: "63c7bf1770f7eb9a8af994f8",
  course_code: "",
  course_title: "Interview Preparation",
  course_createdby_mentor: "MyGreenHorn",
  course_category: "",
  course_duration: "",
  course_description: "",
  course_short_description: "",
  course_price: "Free",
  course_banner_link:
    "https://s3.ap-south-1.amazonaws.com/qa.mygreenhorn.com/data/courseBanners/webdevelopment1.png",
  course_student_enrolled: "",
  course_liked: "",
  course_shared: "",
  course_created_date: "19/04/2023",
  course_videos: [
    {
      video_title: "What to do on the Day of Interview?",
      video_embed_code: "Ze1jM-rqgak",
      video_description: "",
      video_duration: "",
    },
    {
      video_title: "Why do you want to work with us?",
      video_embed_code: "2OLW3N6bD7w",
      video_description: "",
      video_duration: "",
    },
    {
      video_title: "Why should we hire you?",
      video_embed_code: "8b2OJfTCIYk",
      video_description: "",
      video_duration: "",
    },
    {
      video_title: "Tell me about a time you failed",
      video_embed_code: "rlpe197pFQc",
      video_description: "",
      video_duration: "",
    },
    {
      video_title: "What are your greatest strengths?",
      video_embed_code: "J5RgaA39i-U",
      video_description: "",
      video_duration: "",
    },
    {
      video_title: "What are your biggest weaknesses?",
      video_embed_code: "ykpqSxIOC34",
      video_description: "",
      video_duration: "",
    },
    {
      video_title: "What are your short and long term career goals?",
      video_embed_code: "CVfCue8QHFQ",
      video_description: "",
      video_duration: "",
    },
    {
      video_title:
        "5 tips to enhance your presentation and public speaking skills",
      video_embed_code: "wjzDdJ8DPaU",
      video_description: "",
      video_duration: "",
    },
    {
      video_title: "How to stay confident in an interview?",
      video_embed_code: "mQl6DI1w0js",
      video_description: "",
      video_duration: "",
    },
    {
      video_title:
        "5 tips to enhance your presentation and public speaking skills",
      video_embed_code: "wjzDdJ8DPaU",
      video_description: "",
      video_duration: "",
    },
    {
      video_title: "5 Tips to build an outstanding Resume",
      video_embed_code: "q8TU19W1WDg",
      video_description: "",
      video_duration: "",
    },
    {
      video_title: "How to improve verbal communication using 5 simple steps?",
      video_embed_code: "Zf7gi-Pd_cI",
      video_description: "",
      video_duration: "",
    },
    {
      video_title: "How to build your personal brand?",
      video_embed_code: "EL0to1pQpmw",
      video_description: "",
      video_duration: "",
    },
    {
      video_title: "Why do you need to be on LinkedIn?",
      video_embed_code: "MjEyZqYEcM4",
      video_description: "",
      video_duration: "",
    },
    {
      video_title: "How do you crack a Group Discussion",
      video_embed_code: "2vs0i19E5CM",
      video_description: "",
      video_duration: "",
    },
    {
      video_title: "How to lead a Group Discussion? Begin, Middle, End",
      video_embed_code: "WzEL9Xhcb68",
      video_description: "",
      video_duration: "",
    },
  ],
  __v: 0,
  course_videos_count: "5",
};
