import { Link } from "react-router-dom";

const pricing_data = [
  {
    id: 1,
    pricing_plan: [
      // {
      //   price_title: "Career counselling",
      //   price_subtitle: "Learn about yourself to choose your right career",
      //   price: 1999,
      //   navige_link: "/main-payment-page",
      //   ActivatePackage: "CAREERCOUNSELLING",
      //   price_features: [
      //     "Personality assessment",
      //     "Soft skills assessment",
      //     "Aptitude assessment",
      //     "AI cognitive analysis",
      //   ],
      // },

      {
        tag: true,
        price_title: "Communicate for impact",
        price_subtitle:
          "Learn techniques to articulate with confidence in one to one and group settings",
        price: 4499,
        navige_link: "/main-payment-page",
        ActivatePackage: "COMMUNICATION",
        price_features: [
          "Soft skills assessment",
          "AI analysis and report",
          "Verbal communication",
          "Non-verbal communication",
          "Art of presentation",
          "Speaking in a group",
        ],
      },
      {
        price_title: "Crack your dream interview",
        price_subtitle:
          "Learn everything about cracking your next big career interview",
        price: 4999,
        navige_link: "/main-payment-page",
        ActivatePackage: "INTERVIEWPREP",
        price_features: [
          "Communication and confidence",
          "Interview fundamentals",
          "ATS resume",
          "Elevator pitch",
          "LinkedIn",
          "Frequently asked questions",
          "AI interview",
        ],
      },
      {
        price_title: "Build your personal brand",
        price_subtitle:
          "Groom yourself to create the right impression with your stakeholders",
        price: 5999,
        navige_link: "/main-payment-page",
        ActivatePackage: "PERSONALBRANDING",
        price_features: [
          "Personality assessment",
          "Soft skills assessment",
          "SWOT analysis",
          "AI cognitive analysis",
          "Goal setting",
          "Interpersonal skills",
          "Profile building",
          "Professional networking",
          "Resume builder",
        ],
      },
    ],
  },
];
const Pricing = () => {
  return (
    <>
      <section className="price__area">
        <div className="container">
          <div className="row">
            <div className="col-xxl-3 offset-xxl-4">
              <div className="section__title-wrapper mb-60 text-center">
                <h2 className="section__title">
                  Our{" "}
                  <span className="yellow-bg yellow-bg-big">
                    Programs
                    <img src="/assets/img/shape/yellow-bg.png" alt="" />
                  </span>
                </h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="price__tab-content">
                <div className="tab-content" id="nav-tabContent">
                  <div className="row">
                    {pricing_data.map((item, index) => (
                      <div className="row">
                        {item.pricing_plan.map((price, index) => (
                          <div key={index} className="col-md-4">
                            <div
                              className="price__item grey-bg mb-30 p-relative"
                              style={{ padding: "20px" }}
                            >
                              <div className="price__head">
                                <h4>{price.price_title}</h4>
                                <p>{price.price_subtitle}</p>
                              </div>
                              <div className="price__features mb-20 mt-20">
                                <ul>
                                  {price.price_features.map(
                                    (feature, index) => (
                                      <li key={index}>
                                        {!feature == "" ? (
                                          <>
                                            <i className="far fa-check"></i>
                                            {feature}
                                          </>
                                        ) : null}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>

                              <Link
                                to="/main-payment-page"
                                state={{ data: price }}
                                className={`e-btn ${
                                  index === 3 ? "e-btn-4" : "e-btn-border"
                                }`}
                              >
                                Get Started
                              </Link>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Pricing;
