import { lazy, Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ScrollToTop from "../components/scroll-to-top";

import ErrorPage from "../pages/error-page";

import Home from "../pages/home";

import LoginStudent from "../pages/login-student";

import Layout from "../layout/layout";

import ProtectedRoutes from "./ProtectedRoutes";
import StudentDashboard from "../pages/student-dashboard/StudentDashboard";
import EmployerDashboard from "../pages/recuiter-portal/EmployerDashboard";
import Denied from "../pages/denied";
import Jobs from "../pages/jobs/jobs";
import Learning from "../pages/learning/learning";
import ViewSingleCourse from "../pages/learning/viewSingleCourse";
import MentorsLanding from "../pages/mentors-portal/mentorsLanding";
import ResumeBuilderTool from "../pages/resumebuilder/resume-builder-tool";
import ResumeBuilder from "../pages/resumebuilder/resumebuilder";
import JobDescription from "../pages/jobs/job-description";
import JobListings from "../pages/jobs/joblisting";
import CampusEnquiryForm from "../pages/enquiry-forms/campus-enquiry-form";
import StudentEnquiryForm from "../pages/enquiry-forms/student-enquiry-form";
import RecuiterEnquiryForm from "../pages/enquiry-forms/recuiter-enquiry-form";
import MentorEnquiryForm from "../pages/enquiry-forms/mentor-enquiry-form";
import RecuiterSignUp from "../pages/sign-ups/recuiter-signup";
import AdminSignUp from "../pages/sign-ups/admin-signup";
import MentorSignUp from "../pages/sign-ups/mentor-signup";

import ConfidenceBuilder from "../pages/placement-tools/confidencebuilder";
import RecuiterLandingPage from "../pages/recuiter-portal/recuiter-landingpage";
import CampusLandingPage from "../pages/campus-coordinator-portal/campus-landingpage";
import ShowAppliedStudents from "../pages/recuiter-portal/showappliedstudents";
import StudentSignUp from "../pages/sign-ups/student-signup";
import HRMockInterview from "../pages/placement-tools/hrmockinterview";
import TechMockInterview from "../pages/placement-tools/techmockinterview";
import SpeedMentoring from "../pages/placement-tools/speedmentoring";
import GroupDiscussion from "../pages/placement-tools/groupdiscussion";
import MentorDashboard from "../pages/mentors-portal/mentordashboard";
import ManageCourse from "../pages/mentors-portal/managecourse";
import MentorProfile from "../pages/mentors-portal/mentorprofile";
import { Campussignup } from "../services/user-service";
import CampusSignUpPage from "../pages/sign-ups/campus-signup";
import RecuiterCompanyProfile from "../pages/recuiter-portal/recuiterCompanyProfile";
import MentorDetails from "../pages/mentors-portal/mentor-details";
import AppliedStudentsList from "../pages/recuiter-portal/appliedstudentslist";
import CareerPathDetails from "../components/home/careerpathdetails";
import PlacementTools from "../pages/placement-tools/placement-tools";
import PrivacyPolicy from "../pages/important-links/privacypolicy";
import CancellingPolicy from "../pages/important-links/cancellingpolicy";
import ShippingAndDeliveryPolicy from "../pages/important-links/shippinganddelivery";
import TermsAndServicePolicy from "../pages/important-links/termandservice";
import Assessments from "../pages/placement-tools/assessments";
import HomePagePayment from "../components/home/home-payment-page";
import AssessmentsTestAvailable from "../pages/placement-tools/assessment-test-available";
import UploadResumeByGuestEnquiry from "../pages/jobs/upload-resume-by-guest-enquiry";
import StudentToMentorEnquiryForm from "../pages/enquiry-forms/student-to-mentor-enquiry-form";
import AdminDashboard from "../pages/admin-portal/adminDashboard";
import AdminAddJob from "../pages/admin-portal/adminAddJobs";
import PlacementOfficerDashboard from "../pages/campus-coordinator-portal/placementofficier-dashboard";
import AvailableAssessment from "../pages/placement-tools/assessment/user-assessment/available";
import SkillAssessment from "../pages/placement-tools/assessment";
import ScoreCardPage from "../pages/placement-tools/assessment/score-card";
import SoftSkillBeginPage from "../pages/placement-tools/assessment/soft-skill/begin";
import AssessmentBeginPage from "../pages/placement-tools/assessment/aptitude/begin";
//import AvailableTest from "../pages/placement-tools/assessment/available-test";
import AvailableTest from "../pages/placement-tools/assessment/user-assessment/available";
import AdminManageStudentMentor from "../pages/admin-portal/adminManageStudentMentor";
import AdminViewAllUsers from "../pages/admin-portal/adminViewAllUsers";
import MentorListing from "../pages/mentors-portal/mentorListing";
import QRPaymentPage from "../components/home/qapaymentpage";
import AddJob from "../pages/recuiter-portal/addjobs";
import AdminDeleteJobs from "../pages/admin-portal/adminDeleteJobs";
import QuickInterview from "../pages/student-dashboard/quickInterview";
import StudentCollection from "../pages/mentors-portal/student-collection";
import MentorResumeBuilderTool from "../pages/mentors-portal/mentor-resume-builder";
import AIInterviewPage from "../pages/placement-tools/ai-interview-page";
import MentorToStudentCourse from "../pages/mentors-portal/mentor-to-student-course";
import MentorCouponsListing from "../pages/mentors-portal/mentor-coupons-listing";
import MentorCouponsUsedStudentListing from "../pages/mentors-portal/mentor-coupons-used-student-list";
import AdminViewAllPremiumUsers from "../pages/admin-portal/adminViewAllPremiumUsers";
import ResumeBuilderToolOptionTwo from "../pages/resumebuilder/resume-builder-tool-option-two";
import ResumeBuilderToolOptionThree from "../pages/resumebuilder/resume-builder-tool-option-three";
import ResumeBuilderToolOptionFour from "../pages/resumebuilder/resume-builder-tool-option-four";
import MixBeginPage from "../pages/placement-tools/new-single-assessment/single-begin";
import MixScoreCardPage from "../pages/placement-tools/new-single-assessment/single-score-card";
import AdminPasswordReset from "../pages/admin-portal/adminPasswordReset";
import AdminUserLastLoginDetails from "../pages/admin-portal/adminUserLastLoginDetails";
import AdminManageStudentAccess from "../pages/admin-portal/adminManageStudentAccess";
import BehavioralAssessmentsList from "../pages/placement-tools/assessment/user-assessment/available/behavioral-assessments-list";
import AdminSchedulerListings from "../pages/admin-portal/adminSchedulerListings";
import HomeCustomPagePayment from "../components/home/home-custom-payment";
import MentorPDFUploader from "../pages/mentors-portal/pdf-uploader";
import PdfUploader from "../pages/mentors-portal/pdf-uploader";
import MentorCourse from "../pages/mentors-portal/mentor-course";
import PdfFullScreenViewer from "../pages/mentors-portal/pdfFullscreenPage";
import AdminAIManager from "../pages/admin-portal/ai-manager";
import PersonalBranding from "../pages/mentors-portal/Personal-branding-bucket";

import PdfFullScreenViewerBucket from "../pages/mentors-portal/pdfFullscreenPageBucket";
import InterviewPreparationBucket from "../pages/mentors-portal/interview-preparation-bucket";
import CommunicationBucket from "../pages/mentors-portal/art-of-communication-bucket";
import PdfFullScreenViewerCommunicationBucket from "../pages/mentors-portal/pdfFullscreenPageCommunicationBucket";
import PdfFullScreenViewerInterviewBucket from "../pages/mentors-portal/pdfFullscreenPageInterviewBucket";
import ResumeBuilderToolOptionFive from "../pages/resumebuilder/resume-builder-tool-option-five";
import ResumeBuilderParentComponent from "../pages/resumebuilder/resume-builder-parent";
import ResumeBuilderToolOptionSix from "../pages/resumebuilder/resume-builder-tool-option-six";
import WhiteLabledHome from "../components/whiteLabledHome/whiteLabledHome";
import QuizScoreCard from "../pages/placement-tools/new-single-assessment/quiz-score-card";

//const LazySkillAssessment = () => import("../pages/placement-tools/assessment");

const AppNavigation = () => {
  return (
    <>
      {/* <Router>
        <ScrollToTop/> */}
      <Routes>
        <Route exact path="/" element={<Layout />}>
          {/* <Route exact path="/" element={<Home />}></Route> */}

          <Route exact path="/" element={<WhiteLabledHome />}></Route>

          <Route path="/admin-scheduler" element={<AdminSchedulerListings />} />

          <Route path="/jobs" element={<Jobs />}></Route>
          <Route path="/job-listings" element={<JobListings />}></Route>
          <Route path="/career-tools" element={<PlacementTools />}></Route>
          <Route path="/assessments" element={<Assessments />}></Route>
          <Route
            path="/assessments-available"
            element={<AssessmentsTestAvailable />}
          ></Route>
          <Route
            path="/upload-resume-by-guest-enquiry"
            element={<UploadResumeByGuestEnquiry />}
          ></Route>
          <Route
            path="/student-to-mentor-enquiry-form"
            element={<StudentToMentorEnquiryForm />}
          ></Route>
          <Route path="/mentor-course" element={<MentorCourse />}></Route>
          <Route path="/mentor-pdf-manager" element={<PdfUploader />} />
          <Route
            path="/pdf-fullscreen-view"
            element={<PdfFullScreenViewer />}
          />
          <Route
            path="/pdf-personal-fullscreen-view"
            element={<PdfFullScreenViewerBucket />}
          />
          <Route
            path="/pdf-communication-fullscreen-view"
            element={<PdfFullScreenViewerCommunicationBucket />}
          />
          <Route
            path="/pdf-interview-fullscreen-view"
            element={<PdfFullScreenViewerInterviewBucket />}
          />
          <Route
            path="/personal-branding"
            element={<PersonalBranding />}
          ></Route>
          <Route
            path="/art-of-communication"
            element={<CommunicationBucket />}
          ></Route>
          <Route
            path="/interview-preparation"
            element={<InterviewPreparationBucket />}
          ></Route>
          <Route
            path="/mentor-coupons-listing"
            element={<MentorCouponsListing />}
          ></Route>
          <Route
            path="/mentor-coupons-usedby-students"
            element={<MentorCouponsUsedStudentListing />}
          ></Route>
          <Route
            path="/main-payment-page"
            element={<HomePagePayment />}
          ></Route>
          <Route
            path="/custom-payment-page"
            element={<HomeCustomPagePayment />}
          ></Route>
          <Route path="/qr-payment-page" element={<QRPaymentPage />}></Route>
          <Route
            path="/career-path-details"
            element={<CareerPathDetails />}
          ></Route>
          <Route
            path="/single-job-description"
            element={<JobDescription />}
          ></Route>

          <Route path="/confidence-builder" element={<ConfidenceBuilder />} />
          {/* New Pages */}
          <Route path="/mentor-dashboard" element={<MentorDashboard />} />
          <Route
            path="/mentor-resume-builder-tool"
            element={<MentorResumeBuilderTool />}
          />
          <Route path="/mentor-profile" element={<MentorProfile />} />
          <Route path="/manage-course" element={<ManageCourse />} />
          <Route path="/mentor-details" element={<MentorDetails />} />
          <Route path="/campus-signup" element={<CampusSignUpPage />} />
          <Route path="/company-profile" element={<RecuiterCompanyProfile />} />
          <Route
            path="/student-enquiry-form"
            element={<StudentEnquiryForm />}
          />
          <Route path="/campus-enquiry-form" element={<CampusEnquiryForm />} />
          <Route
            path="/recuiter-enquiry-form"
            element={<RecuiterEnquiryForm />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/cancellation-and-refund-policy"
            element={<CancellingPolicy />}
          />
          <Route
            path="/shipping and delivery-policy"
            element={<ShippingAndDeliveryPolicy />}
          />
          <Route
            path="/terms-and-condition-policy"
            element={<TermsAndServicePolicy />}
          />
          <Route
            path="/applied-students-list"
            element={<AppliedStudentsList />}
          />
          <Route path="/mentors" element={<MentorListing />} />
          <Route path="/student-collection" element={<StudentCollection />} />
          <Route path="/mentor-enquiry-form" element={<MentorEnquiryForm />} />
          <Route path="/recuiter-signup" element={<RecuiterSignUp />} />
          <Route path="/admin-signup" element={<AdminSignUp />} />
          <Route path="/mentor-signup" element={<MentorSignUp />} />
          <Route path="/denied" element={<Denied />}></Route>
          <Route path="/student-login" element={<LoginStudent />}></Route>
          <Route path="/" element={<ProtectedRoutes roleRequired="STUDENT" />}>
            <Route path="student-dashboard" element={<StudentDashboard />} />
          </Route>
          <Route
            path="/dashboard/quick-interview"
            element={<QuickInterview />}
          />
          <Route path="/view-course" element={<ViewSingleCourse />} />
          <Route
            path="/mentor-to-student-course"
            element={<MentorToStudentCourse />}
          />
          <Route path="/ai-interview" element={<AIInterviewPage />} />

          <Route path="/resume-builder" element={<ResumeBuilder />} />
          <Route
            path="/resume-builder-test-parent-flow"
            element={<ResumeBuilderParentComponent />}
          />
          <Route path="/resume-builder-tool" element={<ResumeBuilderTool />} />
          <Route
            path="/resume-builder-tool-option-two"
            element={<ResumeBuilderToolOptionTwo />}
          />
          <Route
            path="/resume-builder-tool-option-three"
            element={<ResumeBuilderToolOptionThree />}
          />
          <Route
            path="/resume-builder-tool-option-four"
            element={<ResumeBuilderToolOptionFour />}
          />
          <Route
            path="/resume-builder-tool-option-five"
            element={<ResumeBuilderToolOptionFive />}
          />
          <Route
            path="/resume-builder-tool-option-six"
            element={<ResumeBuilderToolOptionSix />}
          />
          <Route path="/become-mentor" element={<MentorsLanding />} />
          <Route path="/recuiter" element={<RecuiterLandingPage />} />
          <Route path="/campus" element={<CampusLandingPage />} />
          <Route path="/student-signup" element={<StudentSignUp />} />
          <Route path="/hr-mock-interview" element={<HRMockInterview />} />
          <Route path="/tech-mock-interview" element={<TechMockInterview />} />
          <Route path="/speed-mentoring" element={<SpeedMentoring />} />
          <Route path="/group-discussion" element={<GroupDiscussion />} />
          <Route path="/" element={<ProtectedRoutes roleRequired="EMPLOYER" />}>
            <Route path="recuiter-dashboard" element={<EmployerDashboard />} />
            <Route path="students-applied" element={<ShowAppliedStudents />} />
          </Route>
          <Route path="/error" element={<ErrorPage />}></Route>
          <Route path="*" element={<ErrorPage />}></Route>
          {/* Student Pages */}
          <Route path="/available-test" element={<AvailableAssessment />} />
          <Route
            path="/behavioral-available-test"
            element={<BehavioralAssessmentsList />}
          />
          {/* <Route
            exact
            path="/assessment/:page/*"
            element={<SkillAssessment />}
          /> */}
          <Route
            exact
            path="/assessment/available-test"
            element={<AvailableTest />}
          />
          <Route
            exact
            path="/assessment/aptitude"
            element={<AssessmentBeginPage />}
          />
          <Route
            exact
            path="/assessment/soft-skill"
            element={<SoftSkillBeginPage />}
          />
          <Route exact path="/assessment/mix" element={<MixBeginPage />} />
          <Route
            exact
            path="/assessment/score-card"
            element={<ScoreCardPage />}
          />
          <Route
            exact
            path="/assessment/mix-score-card"
            element={<MixScoreCardPage />}
          />
          <Route
            exact
            path="/assessment/quiz-score-card"
            element={<QuizScoreCard />}
          />
          {/* Admin Page */}
          <Route path="/admin-ai-manager" element={<AdminAIManager />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route
            path="/admin-password-reset"
            element={<AdminPasswordReset />}
          />
          <Route
            path="/admin-user-last-login-details"
            element={<AdminUserLastLoginDetails />}
          />
          <Route path="/recuiter-add-job" element={<AddJob />} />
          <Route path="/admin-add-job" element={<AdminAddJob />} />
          <Route path="/admin-delete-jobs" element={<AdminDeleteJobs />} />
          <Route
            path="/admin-manage-student-access"
            element={<AdminManageStudentAccess />}
          />
          <Route path="/admin-all-users" element={<AdminViewAllUsers />} />
          <Route
            path="/admin-all-premium-users"
            element={<AdminViewAllPremiumUsers />}
          />
          <Route
            path="/admin-add-student-to-mentor"
            element={<AdminManageStudentMentor />}
          />
          {/* Placement Officer */}
          <Route
            path="/placement-officer-dashboard"
            element={<PlacementOfficerDashboard />}
          />
        </Route>
      </Routes>
      {/* </Router> */}
    </>
  );
};

export default AppNavigation;
