import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { getDataFromLocalStorage } from "../../browser-storage";
import { toast } from "react-toastify";
import {
  AddMentorPDFEntry,
  AddMentorProfile,
  GetMentorProfile,
  UpdateMentorProfileById,
} from "../../services/mentor-service";
import MentorSliderMenu from "./mentorslidermenu";
import { Col, Row } from "react-bootstrap";
import Dropzone from "react-dropzone";
import Modal from "react-modal";
import { toBase64 } from "../../helpers/index";
import styled from "styled-components";
import { UploadMentorPDF } from "../../services/user-service";
import { setDataInLocalStorage } from "../../browser-storage";
import config from "../../services/config";

import { useDropzone } from "react-dropzone";
const DragDropContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 232px;
  border: 1px dashed #c4c4c4;
  border-radius: 5px;
  gap: 8px;
  div:nth-child(1) {
    font-family: Poppins-Bold;
    font-size: 24px;
    color: rgba(61, 41, 36, 0.8);
  }
  div:nth-child(2) {
    font-family: Poppins-Regular;
    font-size: 18px;
    color: rgba(61, 41, 36, 0.8);
  }
  img {
    width: 220px;
    height: 220px;
    border-radius: 5px;
  }
`;

const PdfUploader = () => {
  const [mentorpdfdetials, setMentorPDFDetials] = useState({
    userEmailId: "",
    userId: "",
    MentorEmailId: "",
    pdfName: "",
    pdfDescription: "",
    pdfOrder: "",
    pdfLink: "",
  });

  useEffect(() => {
    const userDetials = getDataFromLocalStorage("user-details");
    console.log(userDetials);

    const currentemail = userDetials.userName;
    GetMentorProfileById(currentemail);
    setMentorPDFDetials({ userEmailId: currentemail });
  }, []);

  let GetMentorProfileById = async (currentemail) => {
    const SubmitResponse = await GetMentorProfile(currentemail)
      .then((resp) => {
        console.log(resp);
        let checkIsProfileCreated = resp;
      })
      .catch((err) => {
        console.log(err);

        toast.error(`Error ${err.response.data} `, {
          position: "top-left",
        });
      });
  };

  //Uploader Code
  //const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [showErrors, setShowErrors] = useState(false);

  //Using Old login
  const setupPDF = async (file) => {
    console.log("file", file);
    console.log(file[0]);
    const base64 = await toBase64(file[0]).catch(() =>
      toast.error(`Unable to Upload Image`, {
        position: "top-left",
      })
    );
    console.log("base64", base64);
    if (base64) {
      setUploadedFile({ uploadedFile: file[0], fileUrl: base64 });
    }
    console.log("uploadedFile", uploadedFile.fileUrl);
    setShowErrors(false);
  };

  const uploadPDF = () => {
    if (!uploadedFile.uploadedFile || showErrors) {
      return;
    }

    UploadMentorPDF(uploadedFile.uploadedFile)
      .then((data) => {
        console.log(data);
        toast.success(`Profile picture updated successfully`, {
          position: "top-left",
        });
        const resp = data.pdfLink;
        setMentorPDFDetials({
          ...mentorpdfdetials,
          pdfLink: resp,
        });
      })
      .catch(() =>
        toast.error(`Unable to update your profile picture`, {
          position: "top-left",
        })
      );
  };

  let ProfileDetailsonChange = (evt) => {
    const value = evt.target.value;
    setMentorPDFDetials({
      ...mentorpdfdetials,
      [evt.target.name]: value,
    });
    console.log(mentorpdfdetials);
  };

  let SubmitMentorPDFEntry = async () => {
    //logic to get uploaded pdf and mix ad upload
    setMentorPDFDetials({
      ...mentorpdfdetials,
      pdfLink:
        "https://s3.ap-south-1.amazonaws.com/qa.mygreenhorn.com/assets/img/ghimages/blankprofile.png",
    });
    console.log(mentorpdfdetials);
    //final entry with pdf link
    const SubmitResponse = await AddMentorPDFEntry(mentorpdfdetials)
      .then((resp) => {
        console.log(resp);
        toast.success(`Submit Successfully `, {
          position: "top-left",
        });
        //window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Error ${err.response.data} `, {
          position: "top-left",
        });
      });
  };

  let SubmitUpdateMentorProfile = async () => {
    const SubmitResponse = await UpdateMentorProfileById(mentorpdfdetials)
      .then((resp) => {
        toast.success(`Submit Successfully `, {
          position: "top-left",
        });
      })
      .catch((err) =>
        toast.error(`Error ${err} `, {
          position: "top-left",
        })
      );
  };

  return (
    <Wrapper>
      <SEO pageTitle={"Mentor Profile"} />
      <MentorSliderMenu />

      <div className="MobTextStyle">
        <div className="container">
          <div style={{ marginTop: 20 }} className="row">
            <div className="col-md-3">
              {uploadedFile !== null ? (
                <>
                  <img src="/assets/img/ghimages/uploadimg.png" />
                  <p style={{ color: "green", fontSize: 18, fontWeight: 900 }}>
                    PDF ready to Upload
                  </p>
                </>
              ) : (
                <Dropzone accept="" onDrop={(file) => setupPDF(file)}>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <DragDropContainer>
                        <>
                          <p>Drag & Drop</p>
                          <p>Or</p>
                          <button className="e-btn">Upload</button>
                        </>
                      </DragDropContainer>
                    </div>
                  )}
                </Dropzone>
              )}

              <div className="row text-center">
                <div className="button-section mt-10 mb-20">
                  <button
                    className="btn btn-primary button add"
                    type="button"
                    onClick={uploadPDF}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="fieldcontainer">
                <p>PDF Title</p>
                <input
                  placeholder="PDF Title"
                  className="form-control marginTop5"
                  type="text"
                  name="pdfName"
                  value={mentorpdfdetials.pdfName}
                  onChange={ProfileDetailsonChange}
                />
              </div>
              <div className="fieldcontainer">
                <p>PDF Description</p>
                <textarea
                  className="form-control"
                  name="pdfDescription"
                  value={mentorpdfdetials.pdfDescription}
                  onChange={ProfileDetailsonChange}
                />
              </div>

              <div className="fieldcontainer">
                <p>PDF Order</p>
                <input
                  placeholder="PDF Order"
                  className="form-control marginTop5"
                  type="number"
                  name="pdfOrder"
                  value={mentorpdfdetials.pdfOrder}
                  onChange={ProfileDetailsonChange}
                />
              </div>
              <div style={{ marginTop: 20 }} className="fieldcontainer">
                {mentorpdfdetials.pdfLink ? (
                  <>
                    <p
                      style={{ color: "green", fontSize: 18, fontWeight: 900 }}
                    >
                      PDF ready to Upload
                    </p>
                  </>
                ) : (
                  <p style={{ color: "red", fontSize: 18, fontWeight: 900 }}>
                    please upload PDF before Adding PDF details
                  </p>
                )}
              </div>
              <div className="row text-center">
                <div className="button-section mt-10 mb-20">
                  {mentorpdfdetials.pdfLink ? (
                    <>
                      <button
                        className="btn btn-primary button add"
                        type="button"
                        onClick={SubmitMentorPDFEntry}
                      >
                        Submit
                      </button>
                    </>
                  ) : (
                    <button
                      className="btn btn-primary button add"
                      type="button"
                      disabled
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default PdfUploader;
